import React from "react";
import { GlobalStyle } from "./COMPONENTS/GlobalStyle";
import Header from "./COMPONENTS/Header";
import HomeScreen from "./COMPONENTS/HomeScreen";

// import Questions from "./COMPONENTS/Questions";

const App = () => {
  return (
    <div>
      <GlobalStyle />
      {/* <Header /> */}
      <HomeScreen />
    </div>
  );
};

export default App;
